import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BottomNav from '../../components/BottomNav'
import { Box } from '../../components/Box'
import Flex from '../../components/Box/Flex'
// import Footer from '../../components/Footer'
import MenuItems from '../../components/MenuItems/MenuItems'
import { SubMenuItems } from '../../components/SubMenuItems'
import { useMatchBreakpoints } from '../../hooks'
import CakePrice from '../../components/CakePrice/CakePrice'
import Logo from './components/Logo'
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT, TOP_BANNER_HEIGHT, TOP_BANNER_HEIGHT_MOBILE } from './config'
import { NavProps } from './types'
// import LangSelector from '../../components/LangSelector/LangSelector'
import { MenuContext } from './context'
import { Button } from '../../components/Button'
import { MenuMobile } from '../../components/Svg'
import Footer from '../../components/Footer'

const GROUND_HEIGHT = 95

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  overflow-x: hidden;
  flex-direction: column;

  &:before {
    content: '';
    z-index: -1;
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: url('/images/sky.png') no-repeat, #fff5fa;
    background-size: cover;
    background-position: 100% 100%;
  }

  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    height: ${GROUND_HEIGHT}px;

    right: 0;
    bottom: -${GROUND_HEIGHT}px;
    left: 0;

    background: url('/images/ground.png') repeat, #fff5fa;
    background-size: cover;
    background-position: 100%;
  }

  ${({ theme }) => theme.mediaQueries.xxs} {
    min-height: 100vh;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: 100vh;
    gap: 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: 100vh;
  }
`

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  transform: translate3d(0, 0, 0);

  ${({ theme }) => theme.mediaQueries.xxs} {
    padding-left: 0;
    padding-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 16px 24px 0;
  }
`

const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: relative;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  left: 0;
  transition: top 0.2s;
  width: 100%;
  z-index: 20;
`

const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  margin: 0 auto;
  width: 100%;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  background: transparent;
`
const StyledFlex = styled(Flex)`
  position: absolute;
  bottom: 0;
  right: 16px;
  transform: translate(0, 100%);
  width: 145px;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 5px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 24px;
  }
`

const MenuDesktopPosition = styled.div`
  width: fit-content;
  margin: auto auto 0;
`

const SocialsMobilePosition = styled.div`
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translate(-50%, 50%);
`

const Menu: React.FC<NavProps> = ({
  linkComponent = 'a',
  userMenu,
  banner,
  globalMenu,
  isDark,
  toggleTheme,
  currentLang,
  setLang,
  cakePriceUsd,
  links,
  subLinks,
  footerLinks,
  activeItem,
  activeSubItem,
  langs,
  buyCakeLabel,
  children,
}) => {
  const { isMobile, isMd } = useMatchBreakpoints()
  const [showMenu, setShowMenu] = useState(true)
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)

  const topBannerHeight = isMobile ? TOP_BANNER_HEIGHT_MOBILE : TOP_BANNER_HEIGHT

  const totalTopMenuHeight = banner ? MENU_HEIGHT + topBannerHeight : MENU_HEIGHT

  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  // const subLinksWithoutMobile = subLinks?.filter((subLink) => !subLink.isMobileOnly)
  // const subLinksMobileOnly = subLinks?.filter((subLink) => subLink.isMobileOnly)

  const [isBottomNavOpen, setIsBottomNavOpen] = useState(false)
  const bottomNavRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (bottomNavRef.current && !bottomNavRef.current.contains(event.target as Node)) {
        setIsBottomNavOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [bottomNavRef])

  return (
    <MenuContext.Provider value={{ linkComponent }}>
      <Wrapper>
        <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
          {/* {banner && <TopBannerContainer height={topBannerHeight}>{banner}</TopBannerContainer>} */}
          <StyledNav justifyContent="space-between">
            {!isMobile ? (
              <>
                <Flex justifyContent="space-between" width="100%" height="100%" alignItems="center" position="relative">
                  <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />

                  <Flex alignItems="center">
                    <Footer />
                    {globalMenu} {userMenu}
                  </Flex>
                </Flex>

                <Flex alignItems="center" height="100%">
                  {!isMobile && !isMd && (
                    <Box mr="12px">
                      <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                    </Box>
                  )}
                </Flex>
              </>
            ) : (
              <>
                <Box mr="8px">
                  <Logo isDark={isDark} href={homeLink?.href ?? '/swap'} />
                </Box>

                <Flex
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="flex-end"
                  position="relative"
                  ref={bottomNavRef}
                  padding="16px"
                >
                  <Flex alignItems="center" height="100%">
                    {!isMobile && !isMd && (
                      <Box mr="12px">
                        <CakePrice showSkeleton={false} cakePriceUsd={cakePriceUsd} />
                      </Box>
                    )}
                    {globalMenu} {userMenu}
                    <Box>
                      <Button
                        style={{ background: 'transparent', padding: '0px', marginLeft: '12px' }}
                        onClick={() => setIsBottomNavOpen(!isBottomNavOpen)}
                      >
                        <MenuMobile color="black" />
                      </Button>

                      {isBottomNavOpen && (
                        <StyledFlex>
                          <BottomNav items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
                        </StyledFlex>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              </>
            )}
          </StyledNav>
        </FixedContainer>
        {subLinks && (
          <Flex justifyContent="space-around">
            {/* {isMobile && (
              <SubMenuItems
                items={subLinksWithoutMobile}
                mt={`${totalTopMenuHeight - 90}px`}
                activeItem={activeSubItem}
              />
            )} */}
          </Flex>
        )}
        <BodyWrapper>
          <Inner isPushed={false} showMenu={showMenu}>
            {children}
          </Inner>
        </BodyWrapper>
        {!isMobile && (
          <MenuDesktopPosition>
            <MenuItems items={links} activeItem={activeItem} activeSubItem={activeSubItem} />
          </MenuDesktopPosition>
        )}
        {isMobile ? (
          <SocialsMobilePosition>
            <Footer />
          </SocialsMobilePosition>
        ) : null}
      </Wrapper>
    </MenuContext.Provider>
  )
}

export default Menu
