/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import { baseColors, darkColors, lightColors } from '../../theme/colors'
import { Flex } from '../Box'
import { Link } from '../Link'
import { StyledFooter } from './styles'
// import { FooterProps } from './types'
// import { ThemeSwitcher } from '../ThemeSwitcher'
// import LangSelector from '../LangSelector/LangSelector'
// import CakePrice from '../CakePrice/CakePrice'
// import { LogoWithTextIcon, ArrowForwardIcon } from '../Svg'
// import { Button } from '../Button'
// import { Colors } from '../..'
import { FooterProps } from './types'
import { TelegramIcon, TwitterIcon } from '../Svg'

const MenuItem: React.FC<FooterProps> = ({ ...props }) => {
  return (
    <StyledFooter {...props}>
      <Flex style={{ gap: '12px' }} mr={['0', , , , '8px']} justifyContent="center" alignItems="center">
        <Flex alignItems="center" justifyContent="center">
          <Link external href="https://x.com/StarexBome">
            <TwitterIcon width="26px" color="white" />
          </Link>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <Link external href="https://t.me/Starex_Group">
            <TelegramIcon width="30px" color="white" />
          </Link>
        </Flex>
      </Flex>
    </StyledFooter>
  )
}

export default MenuItem
