import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 57 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M28.5019 10.8899L47.9753 44.5352H9.02849L28.5019 10.8899ZM30.2328 3.56094C29.4624 2.23037 27.5414 2.23037 26.7711 3.56094L1.79273 46.7053C1.0208 48.0387 1.98292 49.7074 3.52358 49.7074H53.4802C55.0209 49.7074 55.983 48.0387 55.2111 46.7053L30.2328 3.56094Z" />
      <path d="M31.0863 36.7764H25.9141V41.9486H31.0863V36.7764Z" />
      <path d="M31.0863 21.2598H25.9141V34.1903H31.0863V21.2598Z" />
    </Svg>
  )
}

export default Icon
