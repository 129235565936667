import { useTranslation } from 'contexts/Localization'
import { Button, Text, Flex, WarningIcon } from 'packages/uikit'
import { setupNetwork } from 'utils/wallet'
import { useWeb3React } from '@web3-react/core'
import { InjectedConnector } from '@web3-react/injected-connector'

interface WalletWrongNetworkProps {
  onDismiss: () => void
}

const WalletWrongNetwork: React.FC<WalletWrongNetworkProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { connector, library } = useWeb3React()

  const handleSwitchNetwork = async (): Promise<void> => {
    await setupNetwork(library)
    onDismiss?.()
    document.location.reload()
  }

  return (
    <>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Flex
          justifyContent="center"
          width="75px"
          height="75px"
          background="#FFD524"
          borderRadius="50%"
          flexShrink="0"
          mr="8px"
          mb="16px"
        >
          <WarningIcon width="46px" color="#E04311" mb="4px" />
        </Flex>

        <Text color="black" mb="16px" textAlign="center" small bold>
          {t('Wrong Network detected, switch or disconnect to continue')}
        </Text>
      </Flex>
      {/* {connector instanceof InjectedConnector && ( */}
      <Button variant="primary" onClick={handleSwitchNetwork} width="100%" style={{ whiteSpace: 'nowrap' }}>
        <Flex alignItems="center" justifyContent="center" width="100%">
          <Text color="black" small mr="16px">
            Switch Network
          </Text>
        </Flex>
      </Button>
      {/* )} */}
      {/* <Flex width="100%" height="2px" background="rgba(155, 155, 155, 1)" my="16px" />
      <Flex onClick={handleLogout} style={{ cursor: 'pointer' }}>
        <LogoutIcon color="black" width="24px" mr="12px" />
        <Text color="black">Disconnect</Text>
      </Flex> */}
    </>
  )
}

export default WalletWrongNetwork
