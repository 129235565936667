import { BlockIcon, CheckmarkCircleIcon, Flex, Link, RefreshIcon } from 'packages/uikit'
import styled from 'styled-components'
import { TransactionDetails } from 'state/transactions/reducer'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBscScanLink } from 'utils'

interface TransactionRowProps {
  txn: TransactionDetails
}

const TxnIcon = styled(Flex)`
  align-items: center;
  flex: none;
  width: 24px;
`

const Summary = styled.div`
  flex: 1;
  padding: 0 8px;
  color: black;
  font-size: 12px;
`

const TxnLink = styled(Link)`
  align-items: center;
  color: white;
  display: flex;
  margin-bottom: 16px;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
`

const renderIcon = (txn: TransactionDetails) => {
  if (!txn.receipt) {
    return <RefreshIcon spin width="24px" />
  }

  return txn.receipt?.status === 1 || typeof txn.receipt?.status === 'undefined' ? (
    <Flex
      borderRadius="50%"
      background="rgba(75, 151, 63,1)"
      width="24px"
      height="24px"
      justifyContent="center"
      alignItems="center"
    >
      <CheckmarkCircleIcon color="white" width="20px" />
    </Flex>
  ) : (
    <BlockIcon color="failure" width="24px" />
  )
}

const TransactionRow: React.FC<TransactionRowProps> = ({ txn }) => {
  const { chainId } = useActiveWeb3React()

  if (!txn) {
    return null
  }

  return (
    <TxnLink href={getBscScanLink(txn.hash, 'transaction', chainId)} external>
      <Summary>{txn.summary ?? txn.hash}</Summary>
      <TxnIcon>{renderIcon(txn)}</TxnIcon>
    </TxnLink>
  )
}

export default TransactionRow
