import { MenuItemsType, DexIcon, FarmsIcon, StakeIcon, LaunchIcon, AirdropIcon } from 'packages/uikit'
import { ContextApi } from 'contexts/Localization/types'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
// import { perpLangMap } from 'utils/getPerpetualLanguageCode'
import { DropdownMenuItems } from 'packages/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const config: (t: ContextApi['t'], languageCode?: string) => ConfigMenuItemsType[] = (t, languageCode) => [
  {
    label: t('Swap'),
    // icon: DexIcon,
    // fillIcon: DexIcon,
    fillIcon: '/images/sign-menu-1.png',
    href: '/swap',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Pool'),
    // icon: StakeIcon,
    // fillIcon: StakeIcon,
    // href: '/stake',
    fillIcon: '/images/sign-menu-2.png',
    href: '/liquidity',
    showItemsOnMobile: false,
    commingSoon: false,
    items: [],
  },
  {
    label: t('Stake'),
    // icon: StakeIcon,
    // fillIcon: StakeIcon,
    // href: '/stake',
    fillIcon: '/images/sign-menu-2.png',
    href: '/pools',
    showItemsOnMobile: false,
    commingSoon: false,
    items: [],
  },
  {
    label: t('Farms'),
    href: '/farms',
    // href: '#',
    // icon: FarmsIcon,
    // fillIcon: FarmsIcon,
    fillIcon: '/images/sign-menu-3.png',
    showItemsOnMobile: false,
    commingSoon: false,
    items: [],
  },
  // {
  //   label: t('Bridge'),
  //   // href: '/launch',
  //   href: '#',
  //   // icon: LaunchIcon,
  //   // fillIcon: LaunchIcon,
  //   showItemsOnMobile: false,
  //   commingSoon: true,
  //   items: [],
  // },
]

export default config
