import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
      <path d="M13.8307 0.875H11.2474V13.7917H13.8307V0.875ZM20.0695 3.67792L18.2353 5.51208C20.2761 7.1525 21.5807 9.67125 21.5807 12.5C21.5807 17.4987 17.5378 21.5417 12.5391 21.5417C7.54031 21.5417 3.4974 17.4987 3.4974 12.5C3.4974 9.67125 4.80198 7.1525 6.8299 5.49917L5.00865 3.67792C2.50281 5.80917 0.914062 8.96083 0.914062 12.5C0.914062 18.9196 6.11948 24.125 12.5391 24.125C18.9586 24.125 24.1641 18.9196 24.1641 12.5C24.1641 8.96083 22.5753 5.80917 20.0695 3.67792Z" />
    </Svg>
  )
}

export default Icon
