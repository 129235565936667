import { ETHER } from '@starex-dex/sdk'
import {
  Box,
  Button,
  Flex,
  InjectedModalProps,
  // LinkExternal,
  Message,
  Skeleton,
  Text,
  // CopyIcon,
  // LogoutIcon,
} from 'packages/uikit'
// import { useWeb3React } from '@web3-react/core'
import tokens from 'config/constants/tokens'
import { FetchStatus } from 'config/constants/types'
import { useTranslation } from 'contexts/Localization'
// import useAuth from 'hooks/useAuth'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import { CurrencyLogo } from 'components/Logo'
// import { getBscScanLink } from 'utils'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { FlexGap } from 'components/Layout/Flex'
// import { CopyButton } from '../../CopyButton'

interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
  onClick?: () => void
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowBnbBalance, onDismiss, onClick }) => {
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)

  return (
    <>
      <Text color="black" mb="16px" fontSize={['12px', , , , '16px']}>
        {t('Balance')}
      </Text>

      <Flex mb="16px" alignItems="center" justifyContent="space-between">
        <FlexGap gap="6px">
          <CurrencyLogo currency={ETHER} />
          <Text color="black" fontSize={['12px', , , , '16px']}>
            {t('%symbol%', { symbol: ETHER.symbol })}
          </Text>
        </FlexGap>
        {fetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text color="black" fontSize={['12px', , , , '16px']}>
            {formatBigNumber(balance, 6)}
          </Text>
        )}
      </Flex>

      {/* <Flex alignItems="center" justifyContent="space-between" mb="20px">
        <FlexGap gap="6px">
          <CurrencyLogo currency={tokens.cake} />
          <Text color="black" fontSize={['12px', , , , '16px']}>
            {t(`%symbol%`, { symbol: tokens.cake.symbol })}
          </Text>
        </FlexGap>
        {cakeFetchStatus !== FetchStatus.Fetched ? (
          <Skeleton height="22px" width="60px" />
        ) : (
          <Text color="black" fontSize={['12px', , , , '16px']}>
            {getFullDisplayBalance(cakeBalance, 18, 3)}
          </Text>
        )}
      </Flex> */}

      <Button onClick={onClick} mb={hasLowBnbBalance ? '24px' : '0px'}>
        <Text color="black" fontSize={['14px', , , , '16px']}>
          Transactions
        </Text>
      </Button>

      {hasLowBnbBalance && (
        <Message variant="warning">
          <Box>
            <Text color="black" fontWeight="bold" mb="8px" fontSize={['12px', , , , '16px']}>
              {t('%symbol% Balance Low', { symbol: ETHER.symbol })}
            </Text>
            <Text color="black" as="p" small fontSize={['10px', , , , '14px']}>
              {t('You need %symbol% for transaction fees.', { symbol: ETHER.symbol })}
            </Text>
          </Box>
        </Message>
      )}
    </>
  )
}

export default WalletInfo
