import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <g clipPath="url(#clip0_405_12255)">
        <path
          fillRule="evenodd"
          d="M39.553 8.86a3 3 0 014.124 3.252l-4.536 27.514c-.44 2.654-3.352 4.176-5.786 2.854-2.036-1.106-5.06-2.81-7.78-4.588-1.36-.89-5.526-3.74-5.014-5.768.44-1.734 7.44-8.25 11.44-12.124 1.57-1.522.854-2.4-1-1-4.604 3.476-11.996 8.762-14.44 10.25-2.156 1.312-3.28 1.536-4.624 1.312-2.452-.408-4.726-1.04-6.582-1.81-2.508-1.04-2.386-4.488-.002-5.492l34.2-14.4z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_405_12255">
          <path fill="#fff" d="M0 0H48V48H0z" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
