import React, { useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import Flex from '../../../components/Box/Flex'
import { Text } from '../../../components/Text'
// import { LogoIcon, LogoWithTextIcon } from '../../../components/Svg'
import { MenuContext } from '../context'

interface Props {
  isDark: boolean
  href: string
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`

const StyledLink = styled('a')`
  display: flex;
  align-items: center;
  gap: 12px;

  .mobile-icon {
    width: 35px;

    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
  }
  .desktop-icon {
    display: none;

    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
  .eye {
    animation-delay: 20ms;
  }
  &:hover {
    .eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`

const Logo: React.FC<Props> = ({ isDark, href }) => {
  const { linkComponent } = useContext(MenuContext)
  const isAbsoluteUrl = href.startsWith('http')
  const innerLogo = (
    <>
      <img alt="logo mobile" className="mobile-icon" src="/images/logo.png" />
      <img alt="logo desktop" className="desktop-icon" src="/images/logo.png" />
    </>
  )

  return (
    <Flex>
      {isAbsoluteUrl ? (
        <StyledLink as="a" href={href} aria-label="Home page">
          {innerLogo}
          <Text fontSize={['12px', , , , '30px']} lineHeight="1" color="black">
            Starex
          </Text>
        </StyledLink>
      ) : (
        <StyledLink href={href} as={linkComponent} aria-label="Home page">
          {innerLogo}
          <Text fontSize={['12px', , , , '30px']} color="black" lineHeight="1" mt="2px">
            Starex
          </Text>
        </StyledLink>
      )}
    </Flex>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
