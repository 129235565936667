import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { ChainId } from '@starex-dex/sdk'
import { Text } from 'packages/uikit'
import { CHAIN_ID } from './networks'
import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

const serializedTokens = serializeTokens()

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('10000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto Starex</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 1000000,
    tokenImage: {
      primarySrc: `/images/toggle/starex.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake STAREX</Trans>,
    description: (
      <Text fontSize="14px" color="primary">
        <Trans>Core-pool</Trans>
      </Text>
    ),
    autoCompoundFrequency: 5000,
    gasLimit: 1000000,
    tokenImage: {
      primarySrc: `/images/toggle/starex.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
} as const

const pools: SerializedPoolConfig[] =
  chainId === MAINNET
    ? [
        {
          sousId: 0,
          stakingToken: serializedTokens.cake,
          earningToken: serializedTokens.cake,
          contractAddress: {
            9736: '0x3Ec4a3f788435856bf234a51d15C75873b235023',
            9738: '',
          },
          poolCategory: PoolCategory.CORE,
          harvest: true,
          tokenPerBlock: '3.25',
          sortOrder: 1,
          isFinished: false,
        },
        // {
        //   sousId: 1,
        //   stakingToken: serializedTokens.weth,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     9736: '0xD785C643F4077a1a8E7537d5cb7b8b8cbC9886e9',
        //     9738: '',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.04193505099',
        //   sortOrder: 2,
        //   isFinished: false,
        // },
        // {
        //   sousId: 2,
        //   stakingToken: serializedTokens.weth,
        //   earningToken: serializedTokens.weth,
        //   contractAddress: {
        //     9736: '0xC2771678bB2cD9433e7403B800cFba0B48d10DB4',
        //     9738: '',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.1028806584',
        //   sortOrder: 3,
        //   isFinished: false,
        // },
        // {
        //   sousId: 4,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.swapz,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xd6E00EA52eb7bdb5E15A8445Ea7e25c70Dc763a4',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.1286',
        //   sortOrder: 4,
        //   isFinished: true,
        // },
        // {
        //   sousId: 5,
        //   stakingToken: serializedTokens.swapz,
        //   earningToken: serializedTokens.swapz,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0x0b7c46E1f729972499a9Ab45D307101198Ab9BA9',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.239',
        //   sortOrder: 4,
        //   isFinished: true,
        // },
        // {
        //   sousId: 6,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.vdgt,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xbedeBB4c7D336E6E5e5B8685d33Fb3bbfebc4B6B',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.317',
        //   sortOrder: 5,
        //   isFinished: true,
        //   enableEmergencyWithdraw: true,
        // },
        // {
        //   sousId: 7,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.wvlx,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xe68a572CEc1C021d8a13DbDd0bDBCFEdc8F1901f',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.03145',
        //   sortOrder: 6,
        //   isFinished: true,
        // },
        // {
        //   sousId: 8,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.vlxpad,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0x2ABe5242d36E3A0f643EF4748d35Bd8B7FC56C5d',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.0629',
        //   sortOrder: 7,
        //   isFinished: true,
        // },
      ]
    : [
        // {
        //   sousId: 0,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0x055f1edaae61fc18532af5b9e9aecb5d96ec863f',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '1',
        //   sortOrder: 1,
        //   isFinished: false,
        // },
        // {
        //   sousId: 1,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xce45F01A6517D7EF001b46475a8c3c97d871AF60',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.00071444902',
        //   sortOrder: 1,
        //   isFinished: false,
        // },
        // {
        //   sousId: 2,
        //   stakingToken: serializedTokens.usdc,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0x28200E6C1e87080a6ef45032B3ce6e868F2fF5A2',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '1',
        //   sortOrder: 2,
        //   isFinished: true,
        // },
        // {
        //   sousId: 3,
        //   stakingToken: serializedTokens.weth,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0x0A2f3A62A40114585DB7968c6cEc5b6f9F6b97D6',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '1',
        //   sortOrder: 3,
        //   isFinished: true,
        // },
        // {
        //   sousId: 4,
        //   stakingToken: serializedTokens.usdc,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xc967f59d83BE8B4a2c8835b1f9563176e0da4925',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '1',
        //   sortOrder: 4,
        //   isFinished: false,
        // },
        // {
        //   sousId: 5,
        //   stakingToken: serializedTokens.weth,
        //   earningToken: serializedTokens.cake,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xcE4DF8e0eC67533fF0A841A15e50fc19490A2434',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '1',
        //   sortOrder: 5,
        //   isFinished: false,
        // },
        // {
        //   sousId: 6,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.emmet,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0x3D45750782B7c5B7a9b60ac463811E970E55071D',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.00001736111',
        //   sortOrder: 6,
        //   isFinished: true,
        // },
        // {
        //   sousId: 7,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.emmet,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xA0ef0C45999C42950ED8c860863154b46C0230ab',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.0003858024691',
        //   sortOrder: 7,
        //   isFinished: false,
        // },
        // {
        //   sousId: 8,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.zns,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0x15D686b95E2F3b1bf4a766004e5a9509842a477C',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.3858024691',
        //   sortOrder: 8,
        //   isFinished: false,
        // },
        // {
        //   sousId: 9,
        //   stakingToken: serializedTokens.cake,
        //   earningToken: serializedTokens.weth,
        //   contractAddress: {
        //     9736: '',
        //     9738: '0xBfC0DA565cAFde5e50aE27D05B2be62472Dd6008',
        //   },
        //   poolCategory: PoolCategory.CORE,
        //   harvest: true,
        //   tokenPerBlock: '0.00019',
        //   sortOrder: 9,
        //   isFinished: false,
        // },
      ]
export default pools
