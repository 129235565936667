import { useEffect, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  RefreshIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
  UserMenuVariant,
  Box,
  WalletIcon,
  Text,
  Image,
  Link,
} from 'packages/uikit'
import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
import { useRouter } from 'next/router'
import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import { getBscScanLink } from 'utils'
import WalletModal, { WalletView } from './WalletModal'
import ProfileUserMenuItem from './ProfileUserMenuItem'
import WalletUserMenuItem from './WalletUserMenuItem'
import CopyAddress from './CopyAddress'
import UserMenuWrapper from './UserMenuWrapper'

const UserMenu = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  const hasProfile = isInitialized && !!profile
  const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  const onClickWalletMenu = (): void => {
    if (isWrongNetwork) {
      onPresentWrongNetworkModal()
    } else {
      onPresentWalletModal()
    }
  }

  const UserMenuItems = () => {
    return (
      <Box style={{ padding: '5px 15px' }}>
        <Text color="black">{t('Account')}</Text>
        <Flex alignItems="center" style={{ gap: '5px' }}>
          <Image width={24} height={24} src="/images/logo.png" />
          <CopyAddress account={account} />
        </Flex>

        <WalletUserMenuItem isWrongNetwork={isWrongNetwork} onPresentWalletModal={onClickWalletMenu} />

        <UserMenuItem
          style={{ paddingBottom: '10px' }}
          as="button"
          disabled={isWrongNetwork}
          onClick={onPresentTransactionModal}
        >
          {t('Recent Transactions')}
          {hasPendingTransactions && <RefreshIcon color="black" spin />}
        </UserMenuItem>

        <Flex width="100%" height="2px" background="black" my="8px" />

        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" width="100%">
            <LogoutIcon mr="6px" />
            {t('Disconnect')}
          </Flex>
        </UserMenuItem>
      </Box>
    )
  }

  if (account) {
    return (
      <UserMenuWrapper account={account} avatarSrc={avatarSrc} text={userMenuText} variant={userMenuVariable}>
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UserMenuWrapper>
    )
  }

  if (isWrongNetwork) {
    return (
      <UserMenuWrapper text={t('Network')} variant="danger">
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UserMenuWrapper>
    )
  }

  return (
    <ConnectWalletButton
      style={{ background: '#f2fcfc', color: 'black', borderRadius: '8px', height: '45px' }}
      scale="sm"
    >
      <Box display={['none', , , 'block']}>
        <Flex alignItems="center">
          <Text fontSize={['14px', , , , '16px']} color="black">
            <Trans>Connect Wallet</Trans>
          </Text>
          <WalletIcon ml="5px" width="26px" color="black" />
        </Flex>
      </Box>
      <Box display={['block', , , 'none']}>
        <Text fontSize={['14px', , , , '16px']} color="black">
          <Trans>Connect</Trans>
        </Text>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
