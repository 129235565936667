import { ChainId } from '@starex-dex/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

export const VLX_WAG_FARM_PID = chainId === MAINNET ? 1 : 1 // WAG-VLX (2)
export const VLX_USDT_FARM_PID = chainId === MAINNET ? 1 : 1 // BUSD-VLX (3)

const farms: SerializedFarmConfig[] =
  chainId === MAINNET
    ? [
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'StarexDex',
          lpAddresses: {
            9736: '0x783c836bafd54a911e2af95c04b1746a7c4c51b4',
            9738: '',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,

          lpSymbol: 'STAREX-BOME LP',
          lpAddresses: {
            9736: '0x053AAc73731CDd16D86Bd2D9CC935C04C7380707',
            9738: '',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 2,
          v1pid: 2,
          lpSymbol: 'NEIBO-BOME LP',
          lpAddresses: {
            9736: '0x427c87429ee69a629F5736EbDcDB103FB1D9Fd64',
            9738: '',
          },
          token: serializedTokens.syrup,
          quoteToken: serializedTokens.weth,
        },
      ]
    : [
        /**
         * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
         */
        // {
        //   pid: 0,
        //   v1pid: 0,
        //   lpSymbol: 'Starex',
        //   lpAddresses: {
        //     9736: '',
        //     9738: '0x055f1edaae61fc18532af5b9e9aecb5d96ec863f',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 1,
        //   v1pid: 1,
        //   lpSymbol: 'Bome/Starex LP',
        //   lpAddresses: {
        //     9736: '',
        //     9738: '0xF585bC766b1A651B23573f86659d9002bDe03017',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'Tabi/Starex LP',
        //   lpAddresses: {
        //     9736: '',
        //     9738: '0x47675Ab9c2B0903691Fc26Cd04BE31dc5847c75D',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'VLX_TE9 LP',
        //   lpAddresses: {
        //     9736: '0x40d4fC460eF4C76a20CE1BaEAEB6b921050f483f',
        //     9738: '',
        //   },
        //   token: serializedTokens.te9,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 3,
        //   v1pid: 3,
        //   lpSymbol: 'VLX_TE12 LP',
        //   lpAddresses: {
        //     9736: '0xc40969848d5B549138f0b1B499a69fA9B510407d',
        //     9738: '',
        //   },
        //   token: serializedTokens.te12,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 4,
        //   v1pid: 4,
        //   lpSymbol: 'VLX_TE18 LP',
        //   lpAddresses: {
        //     9736: '0xf01D3d7827211626E7b76DD583EaC8b369d046b0',
        //     9738: '',
        //   },
        //   token: serializedTokens.te18,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 5,
        //   v1pid: 5,
        //   lpSymbol: 'VLX_ETH LP',
        //   lpAddresses: {
        //     9736: '0x8A70d2a3e2cba2CAD61FbA419E62eB821F18Bb57',
        //     9738: '',
        //   },
        //   token: serializedTokens.weth,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 6,
        //   v1pid: 6,
        //   lpSymbol: 'VLX_USDT LP',
        //   lpAddresses: {
        //     9736: '0xF20c93c5e5F534C9D95567497Ea17a841164d37b',
        //     9738: '',
        //   },
        //   token: serializedTokens.usdt,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 7,
        //   v1pid: 7,
        //   lpSymbol: 'VLX_USDC LP',
        //   lpAddresses: {
        //     9736: '0x33ea93e391aF9cAA4b8e9C3368236B93DFCF39C4',
        //     9738: '',
        //   },
        //   token: serializedTokens.usdc,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 8,
        //   v1pid: 8,
        //   lpSymbol: 'VLX_BUSD LP',
        //   lpAddresses: {
        //     9736: '0xe25107384e3d23403c4537967D34cCe02A2b56c6',
        //     9738: '',
        //   },
        //   token: serializedTokens.busd,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 9,
        //   v1pid: 9,
        //   lpSymbol: 'VLX_WAG LP',
        //   lpAddresses: {
        //     9736: '0xdC415f9c745a28893b0Cbb6A8eaC1bb6ed42C581',
        //     9738: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.wvlx,
        // },
      ]

export default farms
