import { useDispatch } from 'react-redux'
import { Box, Button, Flex, Text, ChevronLeftIcon } from 'packages/uikit'
import { AppDispatch } from 'state'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { clearAllTransactions } from 'state/transactions/actions'
import orderBy from 'lodash/orderBy'
import TransactionRow from './TransactionRow'

const WalletTransactions: React.FC<{ onBack?: () => void }> = ({ onBack }) => {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const allTransactions = useAllTransactions()
  const sortedTransactions = orderBy(Object.values(allTransactions).filter(isTransactionRecent), 'addedTime', 'desc')

  const handleClearAll = () => {
    if (chainId) {
      dispatch(clearAllTransactions({ chainId }))
    }
  }

  return (
    <Box>
      <Flex alignItems="center" justifyContent="space-between" mb="24px" background="#4B973F" padding={['12px']}>
        <Flex justifyContent="space-between" width="100%">
          <Button variant="icon" onClick={onBack} height="24px">
            <Flex justifyContent="center" border="2px solid black" width="24px" height="24px" borderRadius="50%">
              <ChevronLeftIcon color="black" />
            </Flex>
          </Button>

          <Flex position="absolute" left="50%" style={{ transform: 'translateX(-50%)' }}>
            <Text color="black">{t('Transactions')}</Text>
          </Flex>

          {/* {sortedTransactions.length > 0 && (
            <Button
              scale="sm"
              onClick={handleClearAll}
              px="0"
              style={{ background: 'unset', color: 'black', fontWeight: '400' }}
            >
              {t('Clear all')}
            </Button>
          )} */}
        </Flex>
      </Flex>
      <Box padding="0 24px 24px">
        {sortedTransactions.length > 0 ? (
          sortedTransactions.map((txn) => <TransactionRow key={txn.hash} txn={txn} />)
        ) : (
          <Text color="black" textAlign="center" small>
            {t('Your transactions will appear here')}
          </Text>
        )}
      </Box>
    </Box>
  )
}

export default WalletTransactions
