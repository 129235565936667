import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    // <Svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M8.37124 11.2583C10.4603 12.579 12.0211 14.939 12.0211 14.939H12.0525C12.0525 14.939 15.3685 9.0712 21.5291 5.4624"
    //     stroke="#E81899"
    //     strokeWidth="1.5"
    //     strokeLinecap="square"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M11.7227 21.25C16.8313 21.25 20.9727 17.1086 20.9727 12C20.9727 6.89137 16.8313 2.75 11.7227 2.75C6.61402 2.75 2.47266 6.89137 2.47266 12C2.47266 17.1086 6.61402 21.25 11.7227 21.25Z"
    //     stroke="#E81899"
    //     strokeWidth="1.5"
    //     strokeLinecap="round"
    //   />
    // </Svg>
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M20.293 5.293L9 16.586l-4.293-4.293-1.414 1.414L9 19.414 21.707 6.707l-1.414-1.414z" />
    </Svg>
  )
}

export default Icon
