import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <path d="M27.884 20.784L43.073 3h-3.6L26.288 18.441 15.752 3H3.602l15.93 23.352L3.602 45h3.6l13.926-16.308L32.255 45h12.15L27.884 20.784zm-4.93 5.772l-1.613-2.325L8.498 5.73h5.529l10.362 14.931 1.614 2.325 13.473 19.41h-5.53l-10.991-15.84z" />
    </Svg>
  )
}

export default Icon
