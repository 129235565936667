/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  // createElement,
  memo,
} from 'react'
import styled from 'styled-components'
import { Flex } from '../Box'
import isTouchDevice from '../../util/isTouchDevice'
// import DropdownMenu from '../DropdownMenu/DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import { MenuItemsProps } from './types'
import { Text } from '../Text'
// import AnimatedIconComponent from '../Svg/AnimatedIconComponent'

// const StyledIconWrapper = styled.div<{ isActive?: boolean }>`
//   display: flex;
//   align-items: center;
//   margin-right: 8px;
// `

// const SoonLabel = styled(Text)`
//   position: absolute;
//   right: 0;
//   top: 0;
//   transform: translate(calc(100% + 5px), -50%);
// `

const Position = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, calc(50% - 25px));
`

const BgMenu = styled.div<{ bg: string }>`
  width: 100px;
  height: 100%;
  aspect-ratio: calc(318.15 / 283.27);

  position: relative;
  background: ${({ bg }) => `url(${bg})`} no-repeat;
  background-size: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 218.15px;
  }
`

const StyledFlex = styled(Flex)`
  padding: 20px 0 0;
  border-radius: 20px;
  gap: 76px;

  ${BgMenu} {
    &:first-child {
      transform: translateY(0);

      ${({ theme }) => theme.mediaQueries.lg} {
        transform: translateY(6px);
      }

      ${Position} {
        transform: translate(-50%, calc(50% - 13px)) rotate(6deg);

        ${({ theme }) => theme.mediaQueries.lg} {
          transform: translate(-50%, calc(50% - 25px)) rotate(6deg);
        }
      }
    }

    &:nth-child(n):not(:first-child):not(:last-child) {
      width: 100px;
      aspect-ratio: calc(318.15 / 275.55);

      ${({ theme }) => theme.mediaQueries.lg} {
        width: 200.15px;
        transform: translateY(20px);
      }

      ${Position} {
        transform: translate(-50%, calc(50% - 13px));

        ${({ theme }) => theme.mediaQueries.lg} {
          transform: translate(-50%, calc(50% - 25px));
        }
      }
    }

    &:last-child {
      transform: translateY(0);

      ${({ theme }) => theme.mediaQueries.lg} {
        transform: translateY(6px);
      }

      ${Position} {
        transform: translate(-50%, calc(50% - 13px)) rotate(-6deg);

        ${({ theme }) => theme.mediaQueries.lg} {
          transform: translate(-50%, calc(50% - 25px)) rotate(-6deg);
        }
      }
    }
  }
`

const MenuItems: React.FC<MenuItemsProps> = ({ items = [], activeItem, activeSubItem, ...props }) => {
  return (
    <StyledFlex {...props}>
      {items.map(({ label, items: menuItems = [], href, icon, fillIcon, commingSoon }, i) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color
        const isActive = activeItem === href
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href }
        const Icon = icon
        return (
          <BgMenu bg={fillIcon}>
            <Position>
              <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor} isComming={commingSoon} key={i}>
                {/* {icon && (
                  <StyledIconWrapper isActive={isActive}>
                    <AnimatedIconComponent icon={icon} fillIcon={fillIcon} width="25px" height="25px" isActive={isActive} />
                  </StyledIconWrapper>
                )} */}
                <Text fontWeight="700" fontSize={['12px', , , , '20px']}>
                  {label}
                </Text>
              </MenuItem>
            </Position>
          </BgMenu>
        )
      })}
    </StyledFlex>
  )
}

export default memo(MenuItems)
